@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Hand";
    font-display: block;
    src: url(fonts/JustAnotherHand-Regular.ttf) format("truetype");
  }
}

html,
body,
#root {
  height: 100%;
  background-color: #fff0d3;
}

section {
  max-width: 800px;
}
